import { FC, PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Check } from '@phosphor-icons/react';
import { Variants, motion } from 'framer-motion';

import { Button } from '@shared/components/Button';
import { NotificationsComponentProvider } from '@shared/components/Notifications/contexts/NotificationsComponent';
import { useNotificationsComponent } from '@shared/components/Notifications/hooks/useNotificationsComponent';
import {
  INotificationsItemProps,
  INotificationsRootProps,
} from '@shared/components/Notifications/types/NotificationsTypes';
import { Paginate } from '@shared/components/Paginate';
import { Text } from '@shared/components/Text';

import { useNotifications } from '@modules/notifications/hooks/useNotifications';

// ------------------------------------------

const NotificationsRoot: FC<PropsWithChildren<INotificationsRootProps>> = ({ children }): ReactElement => {
  return (
    <NotificationsComponentProvider>
      <ul className="flex flex-col">{children}</ul>
    </NotificationsComponentProvider>
  );
};

NotificationsRoot.displayName = 'Notifications.Root';

// ------------------------------------------

const NotificationsHeader: FC = (): ReactElement => {
  const { t } = useTranslation('notifications');

  const { getNotifications, updateNotifications, notificationsFilter, notifications } = useNotifications();
  const { notificationsSelected, handleNotificationsSelected } = useNotificationsComponent();

  const handleNotificationUnread = async (): Promise<void> => {
    await updateNotifications(notificationsSelected.map(notification => ({ id: notification.id, read: false })));

    await getNotifications({ limit: notificationsFilter.limit, page: notificationsFilter.page });
  };

  const handleNotificationRead = async (): Promise<void> => {
    await updateNotifications(notificationsSelected.map(notification => ({ id: notification.id, read: true })));

    await getNotifications({ limit: notificationsFilter.limit, page: notificationsFilter.page });
  };

  const handleSelectAllNotifications = (): void => {
    notifications.items.map(notification => handleNotificationsSelected(notification));
  };

  return (
    <section className="flex items-center">
      <button
        type="button"
        data-is-selected={notificationsSelected.length === notifications.items?.length}
        className="ml-8 mr-3 flex h-4 w-4 items-center justify-center rounded-sm border-[1px] border-black-400 transition-all duration-200 data-[is-selected=true]:border-green-500 data-[is-selected=true]:bg-green-500 dark:border-gray-600"
        onClick={handleSelectAllNotifications}
      >
        {notificationsSelected.length === notifications.items?.length && (
          <Check size={12} className="text-white" weight="bold" />
        )}
      </button>
      <Text className="text-black-400 dark:text-white">{t('select_all')}</Text>

      <div className="ml-auto flex gap-2">
        <Button
          variant="tertiary"
          className="mb-1 w-fit self-end font-normal"
          disabled={!notificationsSelected.length}
          onClick={handleNotificationRead}
        >
          {t('mark_as_read')}
        </Button>

        <Button
          variant="tertiary"
          className="mb-1 w-fit self-end font-normal"
          disabled={!notificationsSelected.length}
          onClick={handleNotificationUnread}
        >
          {t('mark_as_unread')}
        </Button>
      </div>
    </section>
  );
};

NotificationsHeader.displayName = 'Notifications.Header';

// ------------------------------------------

const NotificationsItem: FC<PropsWithChildren<INotificationsItemProps>> = ({ index, notification }): ReactElement => {
  const navigate = useNavigate();

  const { updateNotifications } = useNotifications();
  const { handleNotificationsSelected, notificationsSelected } = useNotificationsComponent();

  const handleClickNotification = async (): Promise<void> => {
    if (notification.url) {
      navigate(notification.url);

      if (!notification.read) {
        updateNotifications([{ id: notification.id, read: true }]);
      }
    }
  };

  const notificationIsSelected = (): boolean => {
    return notificationsSelected.map(({ id }) => id).includes(notification.id);
  };

  const variants: Variants = {
    visible: (i: number) => ({ y: 0, opacity: 1, transition: { delay: i * 0.07, type: 'tween' } }),
    hidden: { y: 25, opacity: 0 },
  };

  return (
    <motion.li
      custom={index}
      initial="hidden"
      animate="visible"
      variants={variants}
      data-is-read={notification.read}
      className="flex w-full border-b-[1px] border-black-400/10 bg-green-500/20 px-2 py-4 text-left transition-all duration-200 data-[is-read=true]:bg-background-light hover:bg-green-100 data-[is-read=true]:hover:bg-gray-200/30 dark:border-black-400 data-[is-read=true]:dark:bg-background-dark dark:hover:bg-green-700/20 lg:p-8"
    >
      {/* Checkbox */}
      <button
        type="button"
        data-is-selected={notificationsSelected.map(({ id }) => id).includes(notification.id)}
        onClick={() => handleNotificationsSelected(notification)}
        className="flex h-4 w-4 items-center justify-center rounded-sm border-[1px] border-black-400 transition-all duration-200 data-[is-selected=true]:border-green-500 data-[is-selected=true]:bg-green-500 dark:border-gray-600"
      >
        {notificationIsSelected() && <Check size={12} className="text-white" weight="bold" />}
      </button>

      {/* Content */}
      <button
        type="button"
        onClick={handleClickNotification}
        className="ml-3 flex h-full w-full flex-1 flex-col items-center text-left lg:flex-row"
      >
        {/* Body */}
        <div className="flex flex-1 flex-col">
          <Text className="font-semibold text-black-400 dark:text-white">{notification.title}</Text>
          <Text className="mt-2 text-sm text-gray-500">{notification.message}</Text>
        </div>

        {/* Created at */}
        <Text className="mt-3 self-end text-xs text-gray-500 lg:mt-0 lg:self-center">
          {notification.createdAtFormatted}
        </Text>
      </button>
    </motion.li>
  );
};

NotificationsItem.displayName = 'Notifications.Item';

// ------------------------------------------

const NotificationsPaginate: FC = (): ReactElement => {
  const { getNotifications, notifications, handleFilterNotifications } = useNotifications();

  const handlePageChange = async (selected: number): Promise<void> => {
    await getNotifications({ limit: 10, page: selected + 1 });

    handleFilterNotifications({ limit: 10, page: selected + 1 });
  };

  return (
    <Paginate
      pageCount={notifications.qtPages}
      onPageChange={({ selected }) => handlePageChange(selected)}
      containerClassName="flex items-center justify-center mt-10 gap-4 text-sm text-black-400 dark:text-white antialiased leading-none font-sans"
      activeClassName="text-green-500 font-semibold"
    />
  );
};

NotificationsPaginate.displayName = 'Notifications.Paginate';

// ------------------------------------------

export const Notifications = {
  Header: NotificationsHeader,
  Item: NotificationsItem,
  Paginate: NotificationsPaginate,
  Root: NotificationsRoot,
};
