/* eslint-disable import/no-duplicates */

import { useCallback, useMemo, useState } from 'react';

import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { createContext } from 'use-context-selector';

import { NotificationsClient } from '@shared/clients/http/NotificationsClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { INotificationsContext } from '@modules/notifications/types/Notifications/context';
import { INotification } from '@modules/notifications/types/Notifications/notifications';
import {
  IGetNotificationsRequest,
  IUpdateNotificationRequest,
} from '@modules/notifications/types/Notifications/requests';

const NotificationsContext = createContext({} as INotificationsContext);
NotificationsContext.displayName = 'Notifications';

const NotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const locales: { [key: string]: Locale } = useMemo(() => ({ 'pt-BR': ptBR }), []);

  const [notifications, setNotifications] = useState({} as IPaginateDTO<INotification>);
  const [notificationsFilter, setNotificationsFilter] = useState({ limit: 10, page: 1 } as IGetNotificationsRequest);

  const getNotifications = useCallback(
    async (data: IGetNotificationsRequest) => {
      try {
        startLoad();

        const response = await NotificationsClient.notifications().getNotifications(data);

        setNotifications({
          ...response.data,
          items: response.data.items.map(notification => ({
            ...notification,
            createdAtFormatted: formatDistance(parseISO(notification.createdAt), new Date(), {
              addSuffix: true,
              locale: locales['pt-BR'],
            }),
          })),
        });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, locales, startLoad],
  );

  const updateNotifications = useCallback(
    async (data: IUpdateNotificationRequest[]) => {
      try {
        startLoad();

        await NotificationsClient.notifications().updateNotification(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleFilterNotifications = useCallback((data: IGetNotificationsRequest, reset?: boolean) => {
    setNotificationsFilter(current => (reset ? ({ ...data } as IGetNotificationsRequest) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<INotificationsContext>(
    () => ({ getNotifications, handleFilterNotifications, notifications, notificationsFilter, updateNotifications }),
    [getNotifications, handleFilterNotifications, notifications, notificationsFilter, updateNotifications],
  );

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};

export { NotificationsContext, NotificationsProvider };
