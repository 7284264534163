import { AxiosInstance, AxiosResponse } from 'axios';

import { ICompanyAvailableSummary, ICompanySummary } from '@modules/companies/types/Companies/companies';

export class Company {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAvailable(): Promise<AxiosResponse<ICompanyAvailableSummary[]>> {
    return this.instance.get(`/companies/available-to-athletes`);
  }

  getAvailableToSharing(): Promise<AxiosResponse<ICompanySummary[]>> {
    return this.instance.get(`/companies/available-sharing`);
  }
}

export const company = (instance: AxiosInstance): Company => new Company(instance);
