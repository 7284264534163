import { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';

import { CaretLeft, CaretRight, DotsThree } from '@phosphor-icons/react';

import { IPaginateProps } from './types/PaginateProps';

export const Paginate = (props: IPaginateProps): ReactElement => {
  const {
    onPageChange,
    pageCount,
    marginPagesDisplayed = 1,
    pageRangeDisplayed = 2,
    activeClassName,
    containerClassName,
  } = props;

  return (
    <div>
      <ReactPaginate
        onPageChange={onPageChange}
        pageCount={pageCount ?? 1}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        previousLabel={<CaretLeft size={14} weight="bold" className="text-black-400 dark:text-white" />}
        breakLabel={<DotsThree size={14} weight="bold" className="text-black-400 dark:text-white" />}
        nextLabel={<CaretRight size={14} weight="bold" className="text-black-400 dark:text-white" />}
        containerClassName={containerClassName}
        activeClassName={activeClassName}
      />
    </div>
  );
};
