import { AxiosInstance } from 'axios';

import { ProfileAchievement as Achievement } from '@modules/profiles/types/Achievements/achievements';
import {
  CreateProfileAchievementRequest,
  DeleteProfileAchievementRequest,
} from '@modules/profiles/types/Achievements/requests';

export class ProfileAchievement {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Profile achievement ----------

  async create(data: CreateProfileAchievementRequest): Promise<Achievement> {
    const response = await this.instance.post(`/profile/me/achievements`, data);

    return response.data;
  }

  async get(): Promise<Achievement[]> {
    const response = await this.instance.get(`/profile/me/achievements`);

    return response.data;
  }

  async delete({ achievementId }: DeleteProfileAchievementRequest): Promise<void> {
    await this.instance.delete(`/profile/me/achievements/${achievementId}`);
  }
}

export const profileAchievement = (instance: AxiosInstance): ProfileAchievement => new ProfileAchievement(instance);
