/* eslint-disable no-shadow */

export enum AppointmentOrderByEnum {
  DATE = 'date',
  HORSE = 'horse',
  RIDER = 'rider',
  SOURCE = 'source',
  TEACHER = 'teacher',
}

export enum AppointmentOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AppointmentStatusEnum {
  CANCELED = 'CANCELED',
  DENIED = 'DENIED',
  FINISHED = 'FINISHED',
  PENDING_ATHLETE = 'PENDING_ATHLETE',
  PENDING_COMPANY = 'PENDING_COMPANY',
  SCHEDULED = 'SCHEDULED',
}

export enum AppointmentSourceEnum {
  EXPERIMENTAL = 'EXPERIMENTAL',
  FIXED = 'FIXED',
  REPLACEMENT = 'REPLACEMENT',
  SINGLE = 'SINGLE',
}

export interface IAppointmentSummary {
  athleteIsConfirmed: boolean;
  canceledAt?: string;
  companyLogoUrl: string;
  companyName: string;
  date: string;
  done: boolean;
  horsesNames: string[];
  id: string;
  isRequest: boolean;
  observation?: string;
  ridersNames: string[];
  source: AppointmentSourceEnum;
  status: AppointmentStatusEnum;
  teachersNames: string[];
}
