import { AxiosInstance, AxiosResponse } from 'axios';

import { IDeleteProfileShareByIdRequest, IProfileShareRequest } from '@modules/profiles/types/Share/requests';
import { IProfileShare } from '@modules/profiles/types/Share/share';

export class ProfileShare {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  deleteShareById({ shareId }: IDeleteProfileShareByIdRequest): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/profile/shares/${shareId}`);
  }

  getShares(): Promise<AxiosResponse<IProfileShare[]>> {
    return this.instance.get(`/profile/shares`);
  }

  share(data: IProfileShareRequest): Promise<AxiosResponse<void>> {
    return this.instance.post(`/profile/shares`, data);
  }
}

export const profileShare = (instance: AxiosInstance): ProfileShare => new ProfileShare(instance);
