/* eslint-disable import/no-duplicates */

import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { HandleDate } from '@shared/utils/HandleDate';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IDashboardHomeContext } from '@modules/home/types/context';
import { IDashboardHome } from '@modules/home/types/home';
import { IGetDashboardHomeRequest } from '@modules/home/types/requests';

const DashboardHomeContext = createContext({} as IDashboardHomeContext);
DashboardHomeContext.displayName = 'DashboardHome';

const DashboardHomeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [dashboard, setDashboard] = useState({ hasSharing: true, isCompleted: true } as IDashboardHome);

  const getDashboardHome = useCallback(
    async (data: IGetDashboardHomeRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.dashboardHome().getDashboardHome(data);

        const nextInvoices = response.data.nextInvoices.map(invoice => ({
          ...invoice,
          dueFormatted: new HandleDate().setDateAsISO(invoice.due).format('dd/MM/yyyy'),
          paidAtFormatted: invoice.paidAt ? new HandleDate().setDateAsISO(invoice.paidAt).format('dd/MM/yyyy') : '-',
        }));

        const unpaidInvoices = response.data.unpaidInvoices.map(invoice => ({
          ...invoice,
          dueFormatted: new HandleDate().setDateAsISO(invoice.due).format('dd/MM/yyyy'),
          paidAtFormatted: invoice.paidAt ? new HandleDate().setDateAsISO(invoice.paidAt).format('dd/MM/yyyy') : '-',
        }));

        setDashboard({ ...response.data, nextInvoices, unpaidInvoices });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IDashboardHomeContext>(
    () => ({ dashboard, getDashboardHome }),
    [dashboard, getDashboardHome],
  );

  return <DashboardHomeContext.Provider value={contextValue}>{children}</DashboardHomeContext.Provider>;
};

export { DashboardHomeContext, DashboardHomeProvider };
