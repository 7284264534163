import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { addresses, Addresses } from '@modules/addresses/services/Addresses';

import { Appointments, appointments } from '@modules/appointments/services/Appointments';

import { company, Company } from '@modules/companies/services/Companies';

import { companiesApiConfig } from './config';

export class CompaniesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = companiesApiConfig(params).instance();
  }

  addresses(): Addresses {
    return addresses(this.instance);
  }

  appointments(): Appointments {
    return appointments(this.instance);
  }

  companies(): Company {
    return company(this.instance);
  }
}

export const CompaniesFactoryAPI = (params: IClientConfigProps): CompaniesAPI => new CompaniesAPI(params);
