import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { useProfileAbout } from '@modules/profiles/hooks/useProfileAbout';
import { IProfileContext } from '@modules/profiles/types/Profile/context';
import { ICurrentProfile } from '@modules/profiles/types/Profile/profile';
import { IUpdateProfileAvatarRequest, IUpdateProfileRequest } from '@modules/profiles/types/Profile/requests';

const ProfileContext = createContext<IProfileContext>({} as IProfileContext);
ProfileContext.displayName = 'Profile';

const ProfileProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('profiles');

  const { startLoad, endLoad } = useLoader();

  const { currentProfileAbout, updateProfileAboutState } = useProfileAbout();

  const [currentProfile, setCurrentProfile] = usePersistedState(Storage.CURRENT_PROFILE, {} as ICurrentProfile);

  const getCurrentProfile = useCallback(async () => {
    try {
      startLoad();

      const response = await AthletesClient.profile().getCurrentProfile();

      setCurrentProfile(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, setCurrentProfile, startLoad]);

  const updateCurrentProfile = useCallback(
    async (data: IUpdateProfileRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.profile().updateCurrentProfile(data);

        const { profile, address } = response.data;

        setCurrentProfile(current => ({
          ...current,
          address: {
            city: address?.city,
            district: address?.district,
          },
          birthDate: profile.birthDate,
          isComplete: profile.isComplete,
          name: profile.name,
          username: profile.username,
        }));

        updateProfileAboutState({
          address: { ...address, id: currentProfileAbout.address?.id ?? '' },
          birthDate: profile.birthDate,
          contact: { email: profile.email, phone: profile.phone },
          cpf: profile.cpf,
          gender: profile.gender,
          public: response.data.public,
        });

        toast(t('message_profile_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [currentProfileAbout, endLoad, setCurrentProfile, startLoad, updateProfileAboutState, t],
  );

  const updateProfileState = useCallback(
    (data: Partial<ICurrentProfile>, reset?: boolean) => {
      setCurrentProfile(current => (reset ? (data as ICurrentProfile) : { ...current, ...data }));
    },
    [setCurrentProfile],
  );

  const updateProfileAvatar = useCallback(
    async (data: IUpdateProfileAvatarRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.profile().updateCurrentProfileAvatar(data);

        setCurrentProfile(current => ({ ...current, avatarUrl: response.data.avatarUrl }));

        toast(t('message_profile_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, setCurrentProfile, startLoad, t],
  );

  const contextValue = useMemo<IProfileContext>(
    () => ({ currentProfile, getCurrentProfile, updateCurrentProfile, updateProfileAvatar, updateProfileState }),
    [currentProfile, getCurrentProfile, updateCurrentProfile, updateProfileAvatar, updateProfileState],
  );

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export { ProfileProvider, ProfileContext };
