import { lazily } from 'react-lazily';

import { IEquitesRouteObject } from '@shared/routes';

import { NotificationsList } from '@modules/notifications/pages/NotificationsList';

const { AppointmentsCreate } = lazily(() => import('@modules/appointments/pages/AppointmentsCreate'));
const { AppointmentsList } = lazily(() => import('@modules/appointments/pages/AppointmentsList'));

const { FinancialCharges } = lazily(() => import('@modules/financial/pages/FinancialCharges'));

const { Home } = lazily(() => import('@modules/home/pages/Home'));

const { HorsesList } = lazily(() => import('@modules/horses/pages/HorsesList'));

const { Profile } = lazily(() => import('@modules/profiles/pages/Profile'));
const { ProfileEdit } = lazily(() => import('@modules/profiles/pages/ProfileEdit'));

const { Products } = lazily(() => import('@modules/store/pages/Products'));
const { Services } = lazily(() => import('@modules/store/pages/Services'));

export class EquitesRoutes {
  public static privateRoutes: IEquitesRouteObject[] = [
    // App dashboard
    { path: '/', element: <Home />, title: 'dashboard' },

    // Financial
    { path: '/financial/charges', element: <FinancialCharges />, title: 'financial_charges' },

    // Horses
    { path: '/appointments', element: <AppointmentsList />, title: 'appointments_list' },
    { path: '/appointments/schedule', element: <AppointmentsCreate />, title: 'appointments_create' },

    // Horses
    { path: '/stable', element: <HorsesList />, title: 'horses_list' },

    // Notifications
    { path: '/notifications', element: <NotificationsList />, title: 'notifications' },

    // Profile
    { path: '/profile', element: <Profile />, title: 'profile' },
    { path: '/profile/edit', element: <ProfileEdit />, title: 'profile_edit' },

    // Store
    { path: '/store/products', element: <Products />, title: 'store_products' },
    { path: '/store/services', element: <Services />, title: 'store_services' },
  ];
}
