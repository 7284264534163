import { differenceInMonths, differenceInYears, format, isValid, parse, parseISO } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

interface IValidateOptions {
  isValid?: boolean;
  hundredYears?: boolean;
  shouldIsFutureDate?: boolean;
}

export class HandleDate {
  private date: Date;

  private timezone = 'America/Sao_Paulo';

  constructor() {
    this.date = new Date();
  }

  setDate(date: Date): this {
    this.date = date;
    return this;
  }

  setDateAsISO(dateString: string): this {
    this.date = parseISO(dateString);
    return this;
  }

  setDateBR(dateString: string): this {
    this.date = parse(dateString, 'dd/MM/yyyy', new Date());
    return this;
  }

  setDateString(date: string, pattern: string): this {
    this.date = parse(date, pattern, new Date());
    return this;
  }

  setTimeZone(timezone?: string): this {
    if (timezone) this.timezone = timezone;
    return this;
  }

  get(): Date {
    return this.date;
  }

  getTz(): Date {
    return utcToZonedTime(this.date, this.timezone);
  }

  getFormattedWithTimezone(): string {
    return formatInTimeZone(this.date, this.timezone, `yyyy-MM-dd'T'HH:mm:ssXXX`);
  }

  format(pattern: string): string {
    return this.date ? format(this.date, pattern) : '';
  }

  formatTz(pattern: string): string {
    return this.date ? formatInTimeZone(this.date, this.timezone, pattern) : '';
  }

  isValid(options?: IValidateOptions): boolean {
    const validateOptions: IValidateOptions = { isValid: true, hundredYears: true, ...options };

    const today = new Date();

    if (validateOptions.isValid && !isValid(this.date)) return false;
    if (validateOptions.hundredYears && differenceInYears(today, this.date) > 100) return false;
    if (validateOptions.shouldIsFutureDate && differenceInMonths(today, this.date) > 0) return false;

    return true;
  }
}
