import { AppointmentProvider } from '@modules/appointments/contexts/Appointments';
import { AppointmentHistoryProvider } from '@modules/appointments/contexts/AppointmentsHistory';
import { AppointmentRequestProvider } from '@modules/appointments/contexts/AppointmentsRequests';

export const AppAppointmentsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AppointmentProvider>
      <AppointmentHistoryProvider>
        <AppointmentRequestProvider>{children}</AppointmentRequestProvider>
      </AppointmentHistoryProvider>
    </AppointmentProvider>
  );
};
