import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IPartnerItem } from '@modules/store/types/PartnerItem/items';
import { IFindPartnerItemsRequest } from '@modules/store/types/PartnerItem/requests';

export class Partners {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getItems(params: IFindPartnerItemsRequest): Promise<AxiosResponse<IPaginateDTO<IPartnerItem>>> {
    return this.instance.get(`/partners/items`, { params });
  }
}

export const partners = (instance: AxiosInstance): Partners => new Partners(instance);
