import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { ICurrentProfileAbout } from '@modules/profiles/types/About/about';
import { IProfileAboutContext } from '@modules/profiles/types/About/context';

const ProfileAboutContext = createContext<IProfileAboutContext>({} as IProfileAboutContext);
ProfileAboutContext.displayName = 'ProfileAbout';

const ProfileAboutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [currentProfileAbout, setCurrentProfileAbout] = useState({} as ICurrentProfileAbout);

  const getCurrentProfileAbout = useCallback(async () => {
    try {
      startLoad();

      const response = await AthletesClient.profileAbout().getCurrentAbout();

      setCurrentProfileAbout(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const updateProfileAboutState = useCallback((data: Partial<ICurrentProfileAbout>, reset?: boolean) => {
    setCurrentProfileAbout(current => (reset ? { ...data } : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IProfileAboutContext>(
    () => ({ currentProfileAbout, getCurrentProfileAbout, updateProfileAboutState }),
    [currentProfileAbout, getCurrentProfileAbout, updateProfileAboutState],
  );

  return <ProfileAboutContext.Provider value={contextValue}>{children}</ProfileAboutContext.Provider>;
};

export { ProfileAboutProvider, ProfileAboutContext };
