import { ReactElement } from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { Text } from '../Text';
import { ITooltipContentProps, ITooltipRootProps, ITooltipTriggerProps } from './types/TooltipTypes';

// ------------------------------------------

const TooltipRoot = ({ children }: ITooltipRootProps): ReactElement => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={250}>{children}</TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

TooltipRoot.displayName = 'Tooltip.Root';

// ------------------------------------------

const TooltipTrigger = ({ children }: ITooltipTriggerProps): ReactElement => {
  return <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>;
};

TooltipTrigger.displayName = 'Tooltip.Trigger';

// ------------------------------------------

const TooltipContent = ({ text }: ITooltipContentProps): ReactElement => {
  return (
    <TooltipPrimitive.Content sideOffset={4} className="inline-flex items-center rounded-lg bg-black-400 px-4 py-2">
      <TooltipPrimitive.Arrow className="fill-black-400" />
      <Text className="text-xs text-white">{text}</Text>
    </TooltipPrimitive.Content>
  );
};

TooltipContent.displayName = 'Tooltip.Content';

// ------------------------------------------

export const Tooltip = {
  Root: TooltipRoot,
  Trigger: TooltipTrigger,
  Content: TooltipContent,
};
