import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IInvoice } from '@modules/financial/types/Invoices/invoice';
import {
  IFindInvoicesByIdentifierRequest,
  IFindUnpaidInvoicesByIdentifierRequest,
} from '@modules/financial/types/Invoices/requests';

export class Invoice {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  findMyInvoices(params: IFindInvoicesByIdentifierRequest): Promise<AxiosResponse<IPaginateDTO<IInvoice>>> {
    return this.instance.get(`/invoices/me`, { params });
  }

  findMyUnpaidInvoices(params: IFindUnpaidInvoicesByIdentifierRequest): Promise<AxiosResponse<IInvoice[]>> {
    return this.instance.get(`/invoices/unpaid/me`, { params });
  }
}

export const invoice = (instance: AxiosInstance): Invoice => new Invoice(instance);
