import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { PartnersClient } from '@shared/clients/http/PartnersClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IPartnerItemContext } from '@modules/store/types/PartnerItem/context';
import { IPartnerItem } from '@modules/store/types/PartnerItem/items';
import { IFindPartnerItemsRequest } from '@modules/store/types/PartnerItem/requests';

const PartnerItemContext = createContext<IPartnerItemContext>({} as IPartnerItemContext);
PartnerItemContext.displayName = 'PartnerItem';

const PartnerItemProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [partnerItems, setPartnerItems] = useState({} as IPaginateDTO<IPartnerItem>);

  const getPartnersItems = useCallback(
    async (params: IFindPartnerItemsRequest) => {
      try {
        startLoad();

        const response = await PartnersClient.partners().getItems(params);

        setPartnerItems(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IPartnerItemContext>(
    () => ({ getPartnersItems, partnerItems }),
    [getPartnersItems, partnerItems],
  );

  return <PartnerItemContext.Provider value={contextValue}>{children}</PartnerItemContext.Provider>;
};

export { PartnerItemProvider, PartnerItemContext };
