import { AxiosInstance, AxiosResponse } from 'axios';

import { IGetCurrentProfileAboutResponse } from '@modules/profiles/types/About/requests';

export class ProfileAbout {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getCurrentAbout(): Promise<AxiosResponse<IGetCurrentProfileAboutResponse>> {
    return this.instance.get(`/profile/about`);
  }
}

export const profileAbout = (instance: AxiosInstance): ProfileAbout => new ProfileAbout(instance);
