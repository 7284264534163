import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IProfileSportsDataContext } from '@modules/profiles/types/Sports/context';
import { IHandleCurrentProfileSportsDataRequest } from '@modules/profiles/types/Sports/requests';
import { IProfileSportData } from '@modules/profiles/types/Sports/sports';

const ProfileSportsDataContext = createContext({} as IProfileSportsDataContext);
ProfileSportsDataContext.displayName = 'ProfileSportsData';

const ProfileSportsDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('profiles');

  const { startLoad, endLoad } = useLoader();

  const [profileSportsData, setProfileSportsData] = useState({} as IProfileSportData);

  const getCurrentProfileSportsData = useCallback(async () => {
    try {
      startLoad();

      const response = await AthletesClient.profileSportsData().getCurrentSportsData();

      setProfileSportsData(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const handleCurrentProfileSportsData = useCallback(
    async (data: IHandleCurrentProfileSportsDataRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.profileSportsData().handleCurrentSportsData(data);

        setProfileSportsData(response.data);

        toast(t('message_sports_data_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IProfileSportsDataContext>(
    () => ({ getCurrentProfileSportsData, handleCurrentProfileSportsData, profileSportsData }),
    [getCurrentProfileSportsData, handleCurrentProfileSportsData, profileSportsData],
  );

  return <ProfileSportsDataContext.Provider value={contextValue}>{children}</ProfileSportsDataContext.Provider>;
};

export { ProfileSportsDataProvider, ProfileSportsDataContext };
