import { useContextSelector } from 'use-context-selector';

import { AuthContext } from '../contexts/Auth';
import { IAuthContext } from '../types/Auth/context';

export function useAuth(): IAuthContext {
  const signInByTokens = useContextSelector(AuthContext, cs => cs.signInByTokens);
  const isAuthenticated = useContextSelector(AuthContext, cs => cs.isAuthenticated);
  const signOut = useContextSelector(AuthContext, cs => cs.signOut);
  const tokens = useContextSelector(AuthContext, cs => cs.tokens);

  return { isAuthenticated, signInByTokens, signOut, tokens };
}
