import { AxiosInstance, AxiosResponse } from 'axios';

import { IDashboardHome } from '@modules/home/types/home';
import { IGetDashboardHomeRequest } from '@modules/home/types/requests';

export class Home {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getDashboardHome(params: IGetDashboardHomeRequest): Promise<AxiosResponse<IDashboardHome>> {
    return this.instance.get(`/dashboard/home`, { params });
  }
}

export const home = (instance: AxiosInstance): Home => new Home(instance);
