import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IAppointmentRequestContext } from '@modules/appointments/types/AppointmentsRequests/context';
import {
  ICreateAppointmentRequestRequest,
  IUpdateAppointmentRequestRequest,
} from '@modules/appointments/types/AppointmentsRequests/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const AppointmentRequestContext = createContext({} as IAppointmentRequestContext);
AppointmentRequestContext.displayName = 'AppointmentRequest';

const AppointmentRequestProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('appointments');

  const navigate = useNavigate();

  const { startLoad, endLoad } = useLoader();

  const createAppointmentRequest = useCallback(
    async (data: ICreateAppointmentRequestRequest) => {
      try {
        startLoad();

        await CompaniesClient.appointments().createAppointmentRequest(data);

        navigate('/appointments');

        toast(t('message_appointment_request_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, navigate, startLoad, t],
  );

  const updateAppointmentRequest = useCallback(
    async (data: IUpdateAppointmentRequestRequest) => {
      try {
        startLoad();

        await CompaniesClient.appointments().updateAppointmentRequest(data);

        toast(t('message_appointment_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IAppointmentRequestContext>(
    () => ({ createAppointmentRequest, updateAppointmentRequest }),
    [createAppointmentRequest, updateAppointmentRequest],
  );

  return <AppointmentRequestContext.Provider value={contextValue}>{children}</AppointmentRequestContext.Provider>;
};

export { AppointmentRequestContext, AppointmentRequestProvider };
