import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateEmergencyContactRequest,
  ICreateEmergencyContactResponse,
  IDeleteEmergencyContactRequest,
  IGetCurrentProfileHealthResponse,
  IUpdateProfileHealthRequest,
  IUpdateProfileHealthResponse,
} from '@modules/profiles/types/Health/requests';

type IAxiosCreateEmergencyContactResponse = Promise<AxiosResponse<ICreateEmergencyContactResponse>>;
type IAxiosUpdateProfileHealthResponse = Promise<AxiosResponse<IUpdateProfileHealthResponse>>;

export class ProfileHealth {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Profile health

  getCurrentHealth(): Promise<AxiosResponse<IGetCurrentProfileHealthResponse>> {
    return this.instance.get(`/profile/health`);
  }

  updateCurrentHealth(data: IUpdateProfileHealthRequest): IAxiosUpdateProfileHealthResponse {
    return this.instance.post(`/profile/health`, data);
  }

  // Profile emergency contacts

  createEmergencyContact(data: ICreateEmergencyContactRequest): IAxiosCreateEmergencyContactResponse {
    return this.instance.post(`/profile/emergency-contacts`, data);
  }

  deleteEmergencyContact({ id }: IDeleteEmergencyContactRequest): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/profile/emergency-contacts/${id}`);
  }
}

export const profileHealth = (instance: AxiosInstance): ProfileHealth => new ProfileHealth(instance);
