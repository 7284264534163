import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { ICompanyAvailableSummary, ICompanySummary } from '@modules/companies/types/Companies/companies';
import { ICompanyContext } from '@modules/companies/types/Companies/context';

import { useLoader } from '@modules/globals/hooks/useLoader';

const CompaniesContext = createContext({} as ICompanyContext);
CompaniesContext.displayName = 'ProfileAbout';

const CompaniesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [companiesAvailable, setCompaniesAvailable] = useState<ICompanyAvailableSummary[]>([]);
  const [companiesToSharing, setCompaniesToSharing] = useState<ICompanySummary[]>([]);

  const getCompaniesAvailable = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.companies().getAvailable();

      setCompaniesAvailable(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const getCompaniesAvailableToSharing = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.companies().getAvailableToSharing();

      setCompaniesToSharing(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const contextValue = useMemo<ICompanyContext>(
    () => ({ companiesAvailable, companiesToSharing, getCompaniesAvailable, getCompaniesAvailableToSharing }),
    [companiesAvailable, companiesToSharing, getCompaniesAvailable, getCompaniesAvailableToSharing],
  );

  return <CompaniesContext.Provider value={contextValue}>{children}</CompaniesContext.Provider>;
};

export { CompaniesProvider, CompaniesContext };
