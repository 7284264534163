/* eslint-disable import/no-duplicates */

import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { HandleDate } from '@shared/utils/HandleDate';

import { IInvoiceContext } from '@modules/financial/types/Invoices/context';
import { IInvoice } from '@modules/financial/types/Invoices/invoice';
import {
  IFindInvoicesByIdentifierRequest,
  IFindUnpaidInvoicesByIdentifierRequest,
} from '@modules/financial/types/Invoices/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const InvoicesContext = createContext({} as IInvoiceContext);
InvoicesContext.displayName = 'Invoices';

const InvoicesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [invoices, setInvoices] = useState({} as IPaginateDTO<IInvoice>);
  const [invoicesUnpaid, setInvoicesUnpaid] = useState<IInvoice[]>([]);
  const [invoicesFilter, setInvoicesFilter] = useState({ limit: 10, page: 1 } as IFindInvoicesByIdentifierRequest);

  const findMyInvoices = useCallback(
    async (data: IFindInvoicesByIdentifierRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.invoices().findMyInvoices(data);

        const items = response.data.items.map(invoice => ({
          ...invoice,
          dueFormatted: new HandleDate().setDateAsISO(invoice.due).format('dd/MM/yyyy'),
          paidAtFormatted: invoice.paidAt ? new HandleDate().setDateAsISO(invoice.paidAt).format('dd/MM/yyyy') : '-',
        }));

        setInvoices({ ...response.data, items });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const findMyUnpaidInvoices = useCallback(
    async (data: IFindUnpaidInvoicesByIdentifierRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.invoices().findMyUnpaidInvoices(data);

        const items = response.data.map(invoice => ({
          ...invoice,
          dueFormatted: new HandleDate().setDateAsISO(invoice.due).format('dd/MM/yyyy'),
          paidAtFormatted: invoice.paidAt ? new HandleDate().setDateAsISO(invoice.paidAt).format('dd/MM/yyyy') : '-',
        }));

        setInvoicesUnpaid(items);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleInvoicesFilter = useCallback((data: Partial<IFindInvoicesByIdentifierRequest>, reset?: boolean) => {
    setInvoicesFilter(current => (reset ? (data as IFindInvoicesByIdentifierRequest) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IInvoiceContext>(
    () => ({ findMyInvoices, findMyUnpaidInvoices, handleInvoicesFilter, invoices, invoicesFilter, invoicesUnpaid }),
    [findMyInvoices, findMyUnpaidInvoices, handleInvoicesFilter, invoices, invoicesFilter, invoicesUnpaid],
  );

  return <InvoicesContext.Provider value={contextValue}>{children}</InvoicesContext.Provider>;
};

export { InvoicesContext, InvoicesProvider };
