import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IAppointmentSummary } from '@modules/appointments/types/Appointments/appointment';
import {
  ICancelAppointmentByIdentifierIdRequest,
  IFindAppointmentsByIdentifierIdRequest,
} from '@modules/appointments/types/Appointments/requests';
import { IAppointmentRequest } from '@modules/appointments/types/AppointmentsRequests/appointmentRequest';
import {
  ICreateAppointmentRequestRequest,
  IUpdateAppointmentRequestRequest,
} from '@modules/appointments/types/AppointmentsRequests/requests';

type IFindAppointmentResponse = Promise<AxiosResponse<IAppointmentSummary[]>>;
type IFindAppointmentHistoryResponse = Promise<AxiosResponse<IPaginateDTO<IAppointmentSummary>>>;

export class Appointments {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  findAppointments(params: IFindAppointmentsByIdentifierIdRequest): IFindAppointmentResponse {
    return this.instance.get(`/appointments/athletes`, { params });
  }

  findAppointmentsHistory(params: IFindAppointmentsByIdentifierIdRequest): IFindAppointmentHistoryResponse {
    return this.instance.get(`/appointments/athletes/history`, { params });
  }

  cancelAppointment(data: ICancelAppointmentByIdentifierIdRequest): Promise<AxiosResponse<IAppointmentSummary>> {
    const { appointmentId, ...body } = data;

    return this.instance.post(`/appointments/${appointmentId}/athletes/cancel`, body);
  }

  async createAppointmentRequest(data: ICreateAppointmentRequestRequest): Promise<IAppointmentRequest> {
    const response = await this.instance.post(`/appointments/athletes`, data);

    return response.data;
  }

  updateAppointmentRequest(data: IUpdateAppointmentRequestRequest): Promise<AxiosResponse<IAppointmentRequest>> {
    const { id, ...body } = data;

    return this.instance.patch(`/appointments/athletes/${id}`, body);
  }
}

export const appointments = (instance: AxiosInstance): Appointments => new Appointments(instance);
