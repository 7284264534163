import { FC, PropsWithChildren } from 'react';

import { ProfileAboutProvider } from './About';
import { ProfileHealthProvider } from './Health';
import { ProfileProvider } from './Profile';
import { ProfileShareProvider } from './Share';
import { ProfileSportsDataProvider } from './Sports';

export const AppProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ProfileAboutProvider>
      <ProfileHealthProvider>
        <ProfileProvider>
          <ProfileShareProvider>
            <ProfileSportsDataProvider>{children}</ProfileSportsDataProvider>
          </ProfileShareProvider>
        </ProfileProvider>
      </ProfileHealthProvider>
    </ProfileAboutProvider>
  );
};
