import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IProfileShareContext } from '@modules/profiles/types/Share/context';
import { IDeleteProfileShareByIdRequest, IProfileShareRequest } from '@modules/profiles/types/Share/requests';
import { IProfileShare } from '@modules/profiles/types/Share/share';

const ProfileShareContext = createContext<IProfileShareContext>({} as IProfileShareContext);
ProfileShareContext.displayName = 'ProfileShare';

const ProfileShareProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('profiles');

  const { startLoad, endLoad } = useLoader();

  const [shares, setShares] = useState<IProfileShare[]>([]);

  const getProfileShares = useCallback(async () => {
    try {
      startLoad();

      const response = await AthletesClient.profileShare().getShares();

      setShares(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const deleteProfileShareById = useCallback(
    async (data: IDeleteProfileShareByIdRequest) => {
      try {
        startLoad();

        await AthletesClient.profileShare().deleteShareById(data);

        setShares(current => current.filter(share => share.id !== data.shareId));

        toast(t('message_sharing_delete_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const shareProfile = useCallback(
    async (data: IProfileShareRequest) => {
      try {
        startLoad();

        await AthletesClient.profileShare().share(data);

        toast(t('message_sharing_data_sent_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IProfileShareContext>(
    () => ({ deleteProfileShareById, getProfileShares, shareProfile, shares }),
    [deleteProfileShareById, getProfileShares, shareProfile, shares],
  );

  return <ProfileShareContext.Provider value={contextValue}>{children}</ProfileShareContext.Provider>;
};

export { ProfileShareProvider, ProfileShareContext };
