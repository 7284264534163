import { AxiosInstance, AxiosResponse } from 'axios';

import {
  IGetCurrentProfileResponse,
  IUpdateProfileAvatarRequest,
  IUpdateProfileAvatarResponse,
  IUpdateProfileRequest,
  IUpdateProfileResponse,
} from '@modules/profiles/types/Profile/requests';

type IAxiosUpdateProfileAvatarResponse = Promise<AxiosResponse<IUpdateProfileAvatarResponse>>;

export class Profile {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Profile

  getCurrentProfile(): Promise<AxiosResponse<IGetCurrentProfileResponse>> {
    return this.instance.get(`/profile/me`);
  }

  updateCurrentProfile(data: IUpdateProfileRequest): Promise<AxiosResponse<IUpdateProfileResponse>> {
    return this.instance.patch(`/profile/me`, data);
  }

  // Profile avatar

  updateCurrentProfileAvatar(data: IUpdateProfileAvatarRequest): IAxiosUpdateProfileAvatarResponse {
    const formData = new FormData();
    formData.append('avatar', data.file);

    return this.instance.patch(`/profile/me/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  }
}

export const profile = (instance: AxiosInstance): Profile => new Profile(instance);
