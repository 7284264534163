import axios, { InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { Buffer } from 'buffer';
import { isBefore, parse } from 'date-fns';
import { JwtPayload } from 'jsonwebtoken';

import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { ITokens } from '@modules/auth/types/Auth/auth';
import { ISignInByRefreshResponse } from '@modules/auth/types/Auth/requests';

export class HandleRequestsFunctions {
  private static convertExpirationStringToDate = (exp: string): Date => {
    return parse(exp, 't', new Date());
  };

  private static getPayloadToken(token: string): JwtPayload {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString());

    return JSON.parse(jsonPayload);
  }

  private static expirationIsInvalidFromToken(): boolean {
    const tokens = localStorage.getItem(Storage.TOKENS);

    if (tokens) {
      const { accessToken }: ITokens = JSON.parse(tokens);
      const payload = this.getPayloadToken(accessToken);

      if (payload.exp) {
        return isBefore(this.convertExpirationStringToDate(payload.exp.toString()), new Date());
      }

      return true;
    }

    return true;
  }

  private static async validateAccessToken(): Promise<void> {
    const expirationTokenIsInvalid = this.expirationIsInvalidFromToken();

    const tokens = localStorage.getItem(Storage.TOKENS);

    if (tokens && expirationTokenIsInvalid) {
      try {
        const { accessToken, refreshToken }: ITokens = JSON.parse(tokens);

        const url = `${import.meta.env.VITE_IDENTITY_API_URL}/sessions/refresh-token`;
        const Authorization = `Bearer ${accessToken}`;

        const { data } = await axios.post<ISignInByRefreshResponse>(
          url,
          { profile: 'ATHLETE', refreshToken },
          { headers: { Authorization } },
        );

        localStorage.setItem(
          Storage.TOKENS,
          JSON.stringify({ accessToken: data.accessToken, equitesToken: data.equitesToken, refreshToken }),
        );
      } catch (e) {
        toast('Sua sessão expirou, acesse novamente!', { type: 'error' });
      }
    }
  }

  public static async handleRequest(request: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    const headers = { ...request.headers } as RawAxiosRequestHeaders;

    await this.validateAccessToken();

    if (!headers?.Authorization) {
      const tokens = localStorage.getItem(Storage.TOKENS);

      if (tokens && JSON.parse(tokens)) {
        const { accessToken, equitesToken }: ITokens = JSON.parse(tokens);

        Object.assign(request, {
          headers: {
            ...request.headers,
            Authorization: `Bearer ${accessToken}`,
            'x-Equites-Authorization': `Bearer ${equitesToken}`,
          },
        });
      }
    }

    return request;
  }
}
