import { FC, PropsWithChildren, ReactElement } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '@shared/clients/http/ReactQuery';

import { AppAppointmentsProvider } from '@modules/appointments/contexts';

import { AppAuthProvider } from '@modules/auth/contexts';

import { AppCompaniesProvider } from '@modules/companies/contexts';

import { AppFinancialProvider } from '@modules/financial/contexts';

import { AppProvider } from '@modules/globals/contexts/AppContext';
import { LoaderProvider } from '@modules/globals/contexts/LoaderContext';
import { ThemeProvider } from '@modules/globals/contexts/ThemeContext';

import { AppHomeProvider } from '@modules/home/contexts';

import { AppNotificationsProvider } from '@modules/notifications/contexts';

import { AppProfileProvider } from '@modules/profiles/contexts';

import { AppStoreProvider } from '@modules/store/contexts';

export const EquitesAppProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LoaderProvider>
          <AppProvider>
            <AppAuthProvider>
              <AppCompaniesProvider>
                <AppProfileProvider>
                  <AppStoreProvider>
                    <AppNotificationsProvider>
                      <AppFinancialProvider>
                        <AppHomeProvider>
                          <AppAppointmentsProvider>{children}</AppAppointmentsProvider>
                        </AppHomeProvider>
                      </AppFinancialProvider>
                    </AppNotificationsProvider>
                  </AppStoreProvider>
                </AppProfileProvider>
              </AppCompaniesProvider>
            </AppAuthProvider>
          </AppProvider>
        </LoaderProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
