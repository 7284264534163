import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { Partners, partners } from '@modules/store/services/Partners';

import { partnersApiConfig } from './config';

export class PartnersAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = partnersApiConfig(params).instance();
  }

  partners(): Partners {
    return partners(this.instance);
  }
}

export const PartnersFactoryAPI = (params: IClientConfigProps): PartnersAPI => new PartnersAPI(params);
