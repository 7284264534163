import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IAppointmentSummary } from '@modules/appointments/types/Appointments/appointment';
import { IAppointmentContext } from '@modules/appointments/types/Appointments/context';
import {
  ICancelAppointmentByIdentifierIdRequest,
  IFindAppointmentsByIdentifierIdRequest,
} from '@modules/appointments/types/Appointments/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const AppointmentContext = createContext({} as IAppointmentContext);
AppointmentContext.displayName = 'Appointment';

const AppointmentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('appointments');

  const { startLoad, endLoad } = useLoader();

  const [appointmentsScheduled, setAppointmentsScheduled] = useState<IAppointmentSummary[]>([]);

  const cancelAppointment = useCallback(
    async (data: ICancelAppointmentByIdentifierIdRequest) => {
      try {
        startLoad();

        await CompaniesClient.appointments().cancelAppointment(data);

        toast(t('message_appointment_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const findAppointmentsScheduled = useCallback(
    async (data: IFindAppointmentsByIdentifierIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.appointments().findAppointments(data);

        setAppointmentsScheduled(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const contextValue = useMemo<IAppointmentContext>(
    () => ({ appointmentsScheduled, cancelAppointment, findAppointmentsScheduled }),
    [appointmentsScheduled, cancelAppointment, findAppointmentsScheduled],
  );

  return <AppointmentContext.Provider value={contextValue}>{children}</AppointmentContext.Provider>;
};

export { AppointmentContext, AppointmentProvider };
