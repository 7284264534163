import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { password, Password } from '@modules/auth/services/Password';

import { identityApiConfig } from './config';

export class IdentityAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = identityApiConfig(params).instance();
  }

  password(): Password {
    return password(this.instance);
  }
}

export const IdentityFactoryAPI = (params: IClientConfigProps): IdentityAPI => new IdentityAPI(params);
