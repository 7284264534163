import { useContextSelector } from 'use-context-selector';

import { ProfileContext } from '../contexts/Profile';
import { IProfileContext } from '../types/Profile/context';

export function useProfile(): IProfileContext {
  const currentProfile = useContextSelector(ProfileContext, cs => cs.currentProfile);
  const getCurrentProfile = useContextSelector(ProfileContext, cs => cs.getCurrentProfile);
  const updateCurrentProfile = useContextSelector(ProfileContext, cs => cs.updateCurrentProfile);
  const updateProfileAvatar = useContextSelector(ProfileContext, cs => cs.updateProfileAvatar);
  const updateProfileState = useContextSelector(ProfileContext, cs => cs.updateProfileState);

  return { currentProfile, getCurrentProfile, updateCurrentProfile, updateProfileAvatar, updateProfileState };
}
