import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { BellSlash } from '@phosphor-icons/react';

import { Text } from '@shared/components/Text';

export const NotificationsEmptyState = (): ReactElement => {
  const { t } = useTranslation('notifications');

  return (
    <div className="flex flex-col items-center">
      <div className="flex h-20 w-20 items-center justify-center rounded-full shadow-lg dark:border-[1px] dark:border-black-400">
        <BellSlash size={48} className="text-green-500" />
      </div>

      <div className="w flex flex-col items-center">
        <Text className="mb-2 mt-4 text-center text-lg font-semibold text-black-400 dark:text-white">
          {t('empty_state_notifications_title')}
        </Text>

        <Text className="text-center text-sm font-light text-gray-400">
          {t('empty_state_notifications_description')}
        </Text>
      </div>
    </div>
  );
};
