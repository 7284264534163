/* eslint-disable react/jsx-no-useless-fragment */

import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import axios from 'axios';
import { Buffer } from 'buffer';
import { isBefore, parse } from 'date-fns';

import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';

import { useAuth } from '@modules/auth/hooks/useAuth';
import { ISignInByRefreshResponse } from '@modules/auth/types/Auth/requests';

export const Authenticate: FC<PropsWithChildren> = ({ children }) => {
  const { tokens, signOut } = useAuth();

  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);

  const getPayloadToken = useCallback(async (accessToken: string) => {
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString());

    return JSON.parse(jsonPayload);
  }, []);

  const getExpirationDateFromToken = useCallback((exp: string) => {
    return parse(exp, 't', new Date());
  }, []);

  const expirationIsInvalidFromToken = useCallback(async () => {
    if (tokens) {
      const { accessToken } = tokens;
      const payload = await getPayloadToken(accessToken);

      return isBefore(getExpirationDateFromToken(payload.exp?.toString()), new Date());
    }

    return true;
  }, [getExpirationDateFromToken, getPayloadToken, tokens]);

  const validJwtAccessToken = useCallback(async () => {
    const expirationTokenIsInvalid = await expirationIsInvalidFromToken();

    if (tokens && expirationTokenIsInvalid) {
      try {
        const { accessToken, refreshToken } = tokens;

        const url = `${import.meta.env.VITE_IDENTITY_API_URL}/sessions/refresh-token`;
        const Authorization = `Bearer ${accessToken}`;

        const { data } = await axios.post<ISignInByRefreshResponse>(
          url,
          { profile: 'ATHLETE', refreshToken },
          { headers: { Authorization } },
        );

        localStorage.setItem(
          Storage.TOKENS,
          JSON.stringify({ accessToken: data.accessToken, equitesToken: data.equitesToken, refreshToken }),
        );
      } catch (e) {
        toast('Sua sessão expirou, acesse novamente!', { type: 'error' });
        await signOut();
      }
    }

    setShouldRenderComponent(true);
  }, [expirationIsInvalidFromToken, signOut, tokens]);

  const validHasTokens = useCallback(async () => {
    if (!tokens) await signOut();
  }, [signOut, tokens]);

  const validJwtTokens = useCallback(async () => {
    await validHasTokens();
    await validJwtAccessToken();
  }, [validHasTokens, validJwtAccessToken]);

  useEffect(() => {
    validJwtTokens();

    return () => {
      setShouldRenderComponent(false);
    };
  }, [validJwtTokens]);

  return shouldRenderComponent ? <>{children}</> : null;
};
