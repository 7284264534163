import { useContextSelector } from 'use-context-selector';

import { ProfileAboutContext } from '../contexts/About';
import { IProfileAboutContext } from '../types/About/context';

export function useProfileAbout(): IProfileAboutContext {
  const currentProfileAbout = useContextSelector(ProfileAboutContext, cs => cs.currentProfileAbout);
  const getCurrentProfileAbout = useContextSelector(ProfileAboutContext, cs => cs.getCurrentProfileAbout);
  const updateProfileAboutState = useContextSelector(ProfileAboutContext, cs => cs.updateProfileAboutState);

  return { currentProfileAbout, getCurrentProfileAbout, updateProfileAboutState };
}
