import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { invoice, Invoice } from '@modules/financial/services/Invoices';

import { home, Home } from '@modules/home/services/Home';

import { profileAbout, ProfileAbout } from '@modules/profiles/services/About';
import { ProfileAchievement, profileAchievement } from '@modules/profiles/services/Achievements';
import { profileFinancialOfficer, ProfileFinancialOfficer } from '@modules/profiles/services/FinancialOfficer';
import { ProfileGallery, profileGallery } from '@modules/profiles/services/Gallery';
import { profileHealth, ProfileHealth } from '@modules/profiles/services/Health';
import { profile, Profile } from '@modules/profiles/services/Profile';
import { profileShare, ProfileShare } from '@modules/profiles/services/Share';
import { profileSportsData, ProfileSportsData } from '@modules/profiles/services/Sports';

import { athletesApiConfig } from './config';

export class AthletesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = athletesApiConfig(params).instance();
  }

  dashboardHome(): Home {
    return home(this.instance);
  }

  invoices(): Invoice {
    return invoice(this.instance);
  }

  profile(): Profile {
    return profile(this.instance);
  }

  profileAbout(): ProfileAbout {
    return profileAbout(this.instance);
  }

  profileAchievement(): ProfileAchievement {
    return profileAchievement(this.instance);
  }

  profileGallery(): ProfileGallery {
    return profileGallery(this.instance);
  }

  profileFinancialOfficer(): ProfileFinancialOfficer {
    return profileFinancialOfficer(this.instance);
  }

  profileHealth(): ProfileHealth {
    return profileHealth(this.instance);
  }

  profileShare(): ProfileShare {
    return profileShare(this.instance);
  }

  profileSportsData(): ProfileSportsData {
    return profileSportsData(this.instance);
  }
}

export const AthletesFactoryAPI = (params: IClientConfigProps): AthletesAPI => new AthletesAPI(params);
