import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { IdentityClient } from '@shared/clients/http/IdentityClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { IPasswordContext } from '@modules/auth/types/Password/context';
import { IChangePasswordRequest } from '@modules/auth/types/Password/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const PasswordContext = createContext<IPasswordContext>({} as IPasswordContext);
PasswordContext.displayName = 'Password';

const PasswordProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('auth');

  const { startLoad, endLoad } = useLoader();

  const changePassword = useCallback(
    async (data: IChangePasswordRequest) => {
      try {
        startLoad();

        await IdentityClient.password().changePassword(data);

        toast(t('password_changed_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IPasswordContext>(() => ({ changePassword }), [changePassword]);

  return <PasswordContext.Provider value={contextValue}>{children}</PasswordContext.Provider>;
};

export { PasswordContext, PasswordProvider };
