import '@egjs/react-flicking/dist/flicking.css';

import { ReactElement, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Flicking from '@egjs/react-flicking';
import { ArrowLeft, ArrowRight, FacebookLogo, InstagramLogo, Trophy, User } from '@phosphor-icons/react';

import { Avatar } from '@shared/components/Avatar';
import { Divider } from '@shared/components/Divider';
import { Page } from '@shared/components/Page';
import { Text } from '@shared/components/Text';

type RouteParams = {
  equitesId: string;
};

export const ProfilePublicPage = (): ReactElement => {
  const carouselRef = useRef<Flicking>(null);

  const { equitesId } = useParams<keyof RouteParams>();

  console.log({ equitesId });

  const sliderData = Array.from({ length: 10 }).map((_, index) => ({
    title: `Title ${index}`,
    description: `Description ${index}`,
  }));

  const prevAchievementSlide = async (): Promise<void> => {
    if (carouselRef.current) {
      try {
        await carouselRef.current.prev();
      } catch {
        // No problem
      }
    }
  };
  const nextAchievementSlide = async (): Promise<void> => {
    if (carouselRef.current) {
      try {
        await carouselRef.current.next();
      } catch (error) {
        // No problem
      }
    }
  };

  return (
    <Page className="mx-auto flex h-full w-full flex-col items-center">
      <section className="mt-16 flex w-full max-w-5xl flex-col items-center justify-center gap-3 lg:flex-row lg:items-start lg:gap-6">
        <Avatar.Root size="lg" mode="SQUARE">
          <Avatar.Content icon={User} url={''} altImg={''} />
        </Avatar.Root>

        <section className="flex-1">
          <div className="flex flex-col items-center justify-between lg:flex-row lg:items-baseline">
            <Text asChild className="text-xxl font-bold text-green-500">
              <h1>Nome</h1>
            </Text>

            <Text className="text-lg font-bold text-black-400">City</Text>
          </div>

          <div className="mt-4 flex flex-col items-center gap-4 lg:flex-row">
            <div className="flex items-center gap-4">
              <FacebookLogo size={24} className="text-gray-500" weight="fill" />
              <InstagramLogo size={24} className="text-gray-500" weight="fill" />
            </div>

            <div className="flex items-center gap-4">
              <Text className="text-sm text-gray-500">E-mail</Text>
              <Text className="text-sm text-gray-500">|</Text>
              <Text className="text-sm text-gray-500">Phone</Text>
            </div>
          </div>

          <Text className="mt-6 block text-center text-sm text-black-400 lg:text-left">Description</Text>
        </section>
      </section>

      <Divider mode="full" color="gray" className="w-full max-w-5xl" />

      {/* Achievements */}
      <section className="flex w-full flex-col pt-10 lg:pt-20">
        <div className="mx-auto flex flex-col justify-center gap-3">
          <Text className="text-xxl font-bold text-black-400">Last achievements</Text>
          <hr className="h-1 w-full max-w-24 self-center bg-green-500" />
        </div>

        <section className="mx-auto mt-4 flex min-h-0 w-full min-w-0 max-w-5xl items-center justify-center px-4 py-2 lg:mt-8">
          <Flicking ref={carouselRef} autoResize align="prev" bound circular={false} cameraClass="px-1">
            {sliderData.map(slide => (
              <div key={slide.title} className="m-2 flex w-52 flex-col rounded-lg p-6 shadow-md outline-none">
                <Trophy size={36} className="mx-auto text-green-500" weight="regular" />
                <div className="flex flex-col text-center">
                  <Text className="mt-4 font-semibold text-black-400">{slide.title}</Text>
                  <Text className="mt-2 text-sm text-gray-500">{slide.description}</Text>
                  <Text className="mt-4 text-sm text-black-400">City/UF</Text>
                  <Text className="mt-2 text-xs text-gray-500">01/01/1900</Text>
                </div>
              </div>
            ))}
          </Flicking>
        </section>
        <section className="flex justify-center gap-2">
          <ArrowLeft size={32} onClick={prevAchievementSlide} className="cursor-pointer text-gray-200" />
          <ArrowRight size={32} onClick={nextAchievementSlide} className="cursor-pointer text-gray-200" />
        </section>
      </section>

      {/* Gallery */}
      <section className="mt-16 flex w-full flex-col rounded-t-3xl bg-gray-200/30 pt-10 lg:rounded-t-[100px] lg:pt-20">
        <div className="mx-auto flex flex-col justify-center gap-3">
          <Text className="text-xxl font-bold text-black-400">Gallery</Text>
          <hr className="h-1 w-full max-w-24 self-center bg-green-500" />
        </div>

        <section className="mt-8 w-full max-w-5xl">{/* Gallery slider */}</section>
      </section>

      <footer className="mt-auto w-full justify-center bg-green-500 py-6">
        <div className="mx-auto flex h-full w-full max-w-5xl flex-col items-center justify-between gap-2 lg:flex-row">
          <Text className="text-sm text-white">© Copyright 2024 • Todos os direitos reservados</Text>
          <Text className="text-sm text-white">Feito com carinho pelo Time Equites</Text>
        </div>
      </footer>
    </Page>
  );
};
