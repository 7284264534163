import { FC, HTMLAttributes, PropsWithChildren, ReactElement, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { ArrowDown, SpinnerGap } from '@phosphor-icons/react';
import { MotionProps, motion } from 'framer-motion';

import { useApp } from '@modules/globals/hooks/useApp';

import { Text } from '../Text';

type IPageTypesExtended = HTMLAttributes<HTMLDivElement> & MotionProps;

interface IPageProps extends IPageTypesExtended {
  onRefresh?: () => Promise<void>;
}

export const Page: FC<PropsWithChildren<IPageProps>> = ({ children, onRefresh, ...props }): ReactElement => {
  const { t } = useTranslation('globals');

  const { ptrIsEnabled } = useApp();

  const pageMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.1 } },
    exit: { opacity: 0, transition: { duration: 0.1 } },
  };

  return (
    <Suspense fallback={<Text className="text-black-400 dark:text-white">{t('we_are_loading_you_page')}</Text>}>
      {onRefresh ? (
        <PullToRefresh
          isPullable={ptrIsEnabled}
          onRefresh={onRefresh}
          pullingContent={<ArrowDown size={24} className="mx-auto text-gray-500" />}
          refreshingContent={<SpinnerGap size={24} className="mx-auto animate-spin text-gray-500" />}
          pullDownThreshold={90}
          maxPullDownDistance={90}
          resistance={2.5}
        >
          <motion.main initial="initial" animate="animate" exit="exit" variants={pageMotion} {...props}>
            {children}
          </motion.main>
        </PullToRefresh>
      ) : (
        <motion.main initial="initial" animate="animate" exit="exit" variants={pageMotion} {...props}>
          {children}
        </motion.main>
      )}
    </Suspense>
  );
};
