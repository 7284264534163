import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { INotificationsComponentContext } from '@shared/components/Notifications/types/NotificationsTypes';

import { INotification } from '@modules/notifications/types/Notifications/notifications';

const NotificationsComponentContext = createContext({} as INotificationsComponentContext);

const NotificationsComponentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notificationsSelected, setNotificationsSelected] = useState<INotification[]>([]);

  const handleNotificationsSelected = useCallback((notification: INotification) => {
    setNotificationsSelected(current =>
      current.map(({ id }) => id).includes(notification.id)
        ? current.filter(({ id }) => id !== notification.id)
        : [...current, notification],
    );
  }, []);

  const contextValue = useMemo<INotificationsComponentContext>(
    () => ({ handleNotificationsSelected, notificationsSelected }),
    [handleNotificationsSelected, notificationsSelected],
  );

  return (
    <NotificationsComponentContext.Provider value={contextValue}>{children}</NotificationsComponentContext.Provider>
  );
};

export { NotificationsComponentContext, NotificationsComponentProvider };
