import { AxiosInstance, AxiosResponse } from 'axios';

import { IHandleCurrentProfileSportsDataRequest } from '@modules/profiles/types/Sports/requests';
import { IProfileSportData } from '@modules/profiles/types/Sports/sports';

export class ProfileSportsData {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getCurrentSportsData(): Promise<AxiosResponse<IProfileSportData>> {
    return this.instance.get(`/profile/sports-data`);
  }

  handleCurrentSportsData(data: IHandleCurrentProfileSportsDataRequest): Promise<AxiosResponse<IProfileSportData>> {
    return this.instance.post(`/profile/sports-data`, data);
  }
}

export const profileSportsData = (instance: AxiosInstance): ProfileSportsData => new ProfileSportsData(instance);
