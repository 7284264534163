import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';

import { GoBackPage } from '@shared/components/GoBackPage';
import { Notifications, NotificationsEmptyState } from '@shared/components/Notifications';
import { Page } from '@shared/components/Page';
import { PageTitle } from '@shared/components/PageTitle';

import { useNotifications } from '@modules/notifications/hooks/useNotifications';

export const NotificationsList = (): ReactElement => {
  const { t } = useTranslation('notifications');

  const { getNotifications, notifications, notificationsFilter } = useNotifications();

  const onRefreshPage = async (): Promise<void> => {
    await getNotifications(notificationsFilter);
  };

  useAsync(async () => {
    await getNotifications(notificationsFilter);
  }, [notificationsFilter]);

  return (
    <Page className="flex flex-col px-3 pb-8" onRefresh={onRefreshPage}>
      {/* Page header */}
      <section className="mb-8 flex flex-col">
        <GoBackPage text={t('go_back')} />

        <PageTitle title={t('notifications')} />
      </section>

      {notifications.totalItems ? (
        <Notifications.Root>
          <Notifications.Header />

          {notifications.items.map((notification, index) => (
            <Notifications.Item key={notification.id} index={index} notification={notification} />
          ))}

          <Notifications.Paginate />
        </Notifications.Root>
      ) : (
        <NotificationsEmptyState />
      )}
    </Page>
  );
};
