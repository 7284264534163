import { memo, ReactElement, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';

import { Bell, List } from '@phosphor-icons/react';
import { twMerge } from 'tailwind-merge';

import { NotificationsClient } from '@shared/clients/http/NotificationsClient';
import { Avatar } from '@shared/components/Avatar';
import { Tooltip } from '@shared/components/Tooltip';

import { useSidebar } from '@modules/globals/hooks/useSidebar';

import { useProfile } from '@modules/profiles/hooks/useProfile';

interface IHeaderProps {
  title: string;
}

const HeaderComponent = ({ title }: IHeaderProps): ReactElement => {
  const { t: tRoutes } = useTranslation('routes');
  const { t: tNotifications } = useTranslation('notifications');

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { handleMenuOpened, handleMenuSelected, toggleSidebarOpened } = useSidebar();
  const { currentProfile } = useProfile();

  const [qtNotifications, setQtNotifications] = useState(0);

  const navigateToProfile = (): void => {
    navigate('/profile');
    handleMenuOpened('profile');
    handleMenuSelected('profile');
  };

  useAsync(async () => {
    if (pathname !== '/notifications') {
      const response = await NotificationsClient.notifications().getNotifications({
        limit: 1,
        page: 1,
        read: false,
      });

      setQtNotifications(response.data.totalItems);
    }
  }, [pathname]);

  useEffect(() => {
    if (title) {
      document.title = tRoutes(title);
    }
  }, [tRoutes, title]);

  return (
    <header className="mb-4 flex w-full items-center gap-4 pt-3 lg:mb-8 lg:pt-8">
      <List
        data-is-desktop={isDesktop}
        size={24}
        className="text-green-500 data-[is-desktop=true]:hidden"
        onClick={toggleSidebarOpened}
      />

      <div className="ml-auto flex items-center gap-4">
        <Tooltip.Root>
          <Tooltip.Trigger>
            <Link to="/notifications" className="relative">
              <Bell
                size={24}
                className={twMerge('text-green-500', qtNotifications > 0 ? 'animate-pulse' : 'animate-none')}
                weight={qtNotifications > 0 ? 'fill' : 'regular'}
              />

              {qtNotifications > 0 && (
                <div className="absolute right-[2px] top-0 h-2 w-2 animate-pulse rounded-full bg-red-500" />
              )}
            </Link>
          </Tooltip.Trigger>
          <Tooltip.Content text={tNotifications('notifications')} />
        </Tooltip.Root>
      </div>

      <Avatar.Root classNameContainer="cursor-pointer" mode="ROUNDED" size="xs" onClick={navigateToProfile}>
        <Avatar.Content url={currentProfile.avatarUrl} />
      </Avatar.Root>
    </header>
  );
};

export const Header = memo(HeaderComponent);
