import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { AthletesClient } from '@shared/clients/http/AthletesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IProfileHealthContext } from '@modules/profiles/types/Health/context';
import { ICurrentProfileHealth } from '@modules/profiles/types/Health/health';
import {
  ICreateEmergencyContactRequest,
  IDeleteEmergencyContactRequest,
  IUpdateProfileHealthRequest,
} from '@modules/profiles/types/Health/requests';

const ProfileHealthContext = createContext<IProfileHealthContext>({} as IProfileHealthContext);
ProfileHealthContext.displayName = 'ProfileHealth';

const ProfileHealthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('profiles');

  const { startLoad, endLoad } = useLoader();

  const [currentProfileHealth, setCurrentProfileHealth] = useState({} as ICurrentProfileHealth);

  const getCurrentProfileHealth = useCallback(async () => {
    try {
      startLoad();

      const response = await AthletesClient.profileHealth().getCurrentHealth();

      setCurrentProfileHealth(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const updateProfileHealthState = useCallback((data: Partial<ICurrentProfileHealth>, reset?: boolean) => {
    setCurrentProfileHealth(current =>
      reset ? { ...data, contactsEmergency: data.contactsEmergency ?? [] } : { ...current, ...data },
    );
  }, []);

  const updateProfileHealth = useCallback(
    async (data: IUpdateProfileHealthRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.profileHealth().updateCurrentHealth(data);

        setCurrentProfileHealth(current => ({ ...current, health: response.data }));

        toast(t('message_profile_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const createEmergencyContact = useCallback(
    async (data: ICreateEmergencyContactRequest) => {
      try {
        startLoad();

        const response = await AthletesClient.profileHealth().createEmergencyContact(data);

        setCurrentProfileHealth(current => ({
          ...current,
          contactsEmergency: [...current.contactsEmergency, response.data],
        }));

        toast(t('message_contact_added_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const deleteEmergencyContact = useCallback(
    async (data: IDeleteEmergencyContactRequest) => {
      try {
        startLoad();

        await AthletesClient.profileHealth().deleteEmergencyContact(data);

        setCurrentProfileHealth(current => ({
          ...current,
          contactsEmergency: current.contactsEmergency.filter(ce => ce.id !== data.id),
        }));

        toast(t('message_contact_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const contextValue = useMemo<IProfileHealthContext>(
    () => ({
      createEmergencyContact,
      currentProfileHealth,
      deleteEmergencyContact,
      getCurrentProfileHealth,
      updateProfileHealth,
      updateProfileHealthState,
    }),
    [
      createEmergencyContact,
      currentProfileHealth,
      deleteEmergencyContact,
      getCurrentProfileHealth,
      updateProfileHealth,
      updateProfileHealthState,
    ],
  );

  return <ProfileHealthContext.Provider value={contextValue}>{children}</ProfileHealthContext.Provider>;
};

export { ProfileHealthProvider, ProfileHealthContext };
