import { ElementType } from 'react';

import { IconProps, House, Bag, Horse, Calendar, CurrencyDollar } from '@phosphor-icons/react';
import { MotionProps } from 'framer-motion';

export interface IMenuSubItem {
  id: string;
  name: string; // Key to translate
  path: string;
  permission?: string;
}

export interface IMenuItem extends MotionProps {
  id: string;
  icon: ElementType<IconProps>;
  name: string;
  path: string;
  items?: Array<{ id: string; name: string; path: string }>;
}

export const MenuItems: IMenuItem[] = [
  {
    id: 'home',
    icon: House,
    name: 'home',
    path: '/',
  },
  {
    id: 'appointments',
    icon: Calendar,
    name: 'appointments',
    path: '/appointments',
  },
  {
    id: 'horses',
    icon: Horse,
    name: 'my_stable',
    path: '/stable',
  },
  {
    id: 'financial-charges',
    icon: CurrencyDollar,
    name: 'financial',
    path: '/financial/charges',
  },
  {
    id: 'store',
    icon: Bag,
    name: 'equites_store',
    path: '/store',
    items: [
      { id: 'store-products', name: 'equites_store_products', path: '/store/products' },
      { id: 'store-services', name: 'equites_store_services', path: '/store/services' },
    ],
  },
];
