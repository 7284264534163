import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import {
  AppointmentOrderByEnum,
  AppointmentOrderEnum,
  AppointmentStatusEnum,
  IAppointmentSummary,
} from '@modules/appointments/types/Appointments/appointment';
import { IFindAppointmentsByIdentifierIdRequest } from '@modules/appointments/types/Appointments/requests';
import { IAppointmentHistoryContext } from '@modules/appointments/types/AppointmentsHistory/context';

import { useLoader } from '@modules/globals/hooks/useLoader';

const AppointmentHistoryContext = createContext({} as IAppointmentHistoryContext);
AppointmentHistoryContext.displayName = 'AppointmentsHistory';

const AppointmentHistoryProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { startLoad, endLoad } = useLoader();

  const [appointmentsHistory, setAppointmentsHistory] = useState({} as IPaginateDTO<IAppointmentSummary>);
  const [filterAppointmentsHistory, setFilterAppointmentsHistory] = useState({
    limit: 9,
    page: 1,
    order: AppointmentOrderEnum.DESC,
    orderBy: AppointmentOrderByEnum.DATE,
    status: [AppointmentStatusEnum.FINISHED, AppointmentStatusEnum.CANCELED],
  } as IFindAppointmentsByIdentifierIdRequest);

  const findAppointmentsHistory = useCallback(
    async (data: IFindAppointmentsByIdentifierIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.appointments().findAppointmentsHistory(data);

        setAppointmentsHistory(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleFilterAppointmentsHistory = useCallback(
    (data: Partial<IFindAppointmentsByIdentifierIdRequest>, reset?: boolean) => {
      setFilterAppointmentsHistory(current =>
        reset ? (data as IFindAppointmentsByIdentifierIdRequest) : { ...current, ...data },
      );
    },
    [],
  );

  const contextValue = useMemo<IAppointmentHistoryContext>(
    () => ({
      appointmentsHistory,
      filterAppointmentsHistory,
      findAppointmentsHistory,
      handleFilterAppointmentsHistory,
    }),
    [appointmentsHistory, filterAppointmentsHistory, findAppointmentsHistory, handleFilterAppointmentsHistory],
  );

  return <AppointmentHistoryContext.Provider value={contextValue}>{children}</AppointmentHistoryContext.Provider>;
};

export { AppointmentHistoryContext, AppointmentHistoryProvider };
