import { useContextSelector } from 'use-context-selector';

import { NotificationsContext } from '@modules/notifications/contexts/Notifications';
import { INotificationsContext } from '@modules/notifications/types/Notifications/context';

export function useNotifications(): INotificationsContext {
  const notifications = useContextSelector(NotificationsContext, cs => cs.notifications);
  const notificationsFilter = useContextSelector(NotificationsContext, cs => cs.notificationsFilter);

  const getNotifications = useContextSelector(NotificationsContext, cs => cs.getNotifications);
  const handleFilterNotifications = useContextSelector(NotificationsContext, cs => cs.handleFilterNotifications);
  const updateNotifications = useContextSelector(NotificationsContext, cs => cs.updateNotifications);

  return {
    getNotifications,
    handleFilterNotifications,
    notifications,
    notificationsFilter,
    updateNotifications,
  };
}
