import { AxiosInstance } from 'axios';

import { PersonFinancialOfficer } from '@modules/profiles/types/FinancialOfficer/financialOfficer';
import { CreateOrUpdatePersonFinancialOfficerRequest } from '@modules/profiles/types/FinancialOfficer/requests';

export class ProfileFinancialOfficer {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider financial officer ----------

  async create(data: CreateOrUpdatePersonFinancialOfficerRequest): Promise<PersonFinancialOfficer> {
    const response = await this.instance.post(`/profile/me/financial-officer`, data);

    return response.data;
  }

  async get(): Promise<PersonFinancialOfficer> {
    const response = await this.instance.get(`/profile/me/financial-officer`);

    return response.data;
  }

  async delete(): Promise<void> {
    await this.instance.delete(`/profile/me/financial-officer`);
  }
}

export const profileFinancialOfficer = (instance: AxiosInstance): ProfileFinancialOfficer =>
  new ProfileFinancialOfficer(instance);
