import { AxiosInstance } from 'axios';

import { ProfileGallery as Gallery } from '@modules/profiles/types/Gallery/gallery';
import { DeleteProfileGalleryRequest, UploadProfileGalleryRequest } from '@modules/profiles/types/Gallery/requests';

export class ProfileGallery {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Profile gallery ----------

  async upload({ file }: UploadProfileGalleryRequest): Promise<Gallery> {
    const formData = new FormData();
    formData.append('image', file);

    const headers = { 'Content-Type': 'multipart/form-data' };

    const response = await this.instance.post(`/profile/me/gallery/images`, formData, { headers });

    return response.data;
  }

  async get(): Promise<Gallery[]> {
    const response = await this.instance.get(`/profile/me/gallery/images`);

    return response.data;
  }

  async delete({ galleryId }: DeleteProfileGalleryRequest): Promise<void> {
    await this.instance.delete(`/profile/me/gallery/${galleryId}/images`);
  }
}

export const profileGallery = (instance: AxiosInstance): ProfileGallery => new ProfileGallery(instance);
